export type Light = {
    x: number
    y: number
    on: boolean
}

export type Lights = Light[]

export function buildLights(gridSize: number, state?: number[][]): Lights {
    const lights: Lights = []
    Array.from(Array(gridSize)).forEach((_, y) => {
        Array.from(Array(gridSize)).forEach((_, x) => {
            lights.push({x, y, on: state ? (!!state[y][x]) : false })
        })
    })

    return lights
}

export function lightsAreOut(lights: Light[]): boolean {
    return !(lights.some((light) => {
        return light.on
    }))
}
