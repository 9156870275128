import styles from './Light.module.css'
import classNames from "classnames";
import {Light as LightState} from "./lib/Light";
import {SyntheticEvent} from "react";

interface ClickHandler {
 onClick(e: SyntheticEvent): void
}

type LightProps = LightState & ClickHandler

export default function Light({x, y, on, onClick}: LightProps) {
    return <button onClick={onClick} className={classNames(styles.Light, on ? styles.on : styles.off)}>

    </button>
}
