import React, {useEffect, useState} from 'react';
import styles from './App.module.css';
import Light from "./Light";
import {buildLights, Lights, lightsAreOut} from './lib/Light'
import getBoard from "./lib/Boards";

function App() {

    const [level, setLevel] = useState<number>(Number(localStorage.getItem('currentLevel')) || 0)

    const [lights, setLights] = useState<Lights>(buildLights(5, getBoard(level)))

    const handleLightClick = (id: number) => {
        const targetLight = lights[id];

        const newLights = lights.map((light) => {
            if (
                (light.x >= targetLight.x - 1 && light.x <= targetLight.x + 1 && light.y === targetLight.y) ||
                (light.y >= targetLight.y - 1 && light.y <= targetLight.y + 1 && light.x === targetLight.x)
            ) {
                light.on = !light.on;
            }

            return light
        });

        setLights(newLights)

    }

    useEffect(() => {
        const nextLevel = () => {
            setLevel(level + 1)
            setLights(buildLights(5, getBoard(level + 1)))
            localStorage.setItem('currentLevel', String(level + 1))
        }

        if (lightsAreOut(lights)) {
            nextLevel()
            alert('You Win!')
        }
    }, [level, lights])

    return (
        <div className={styles.Wrapper}>
            <div className={styles.Board}>
                {lights.map((light, idx) => <Light onClick={(e) => {
                    e.preventDefault()
                    handleLightClick(idx)
                }} key={idx} x={light.x} y={light.y} on={light.on}/>)}
            </div>
        </div>
    );
}

export default App;
